import { Img } from "assests/icons";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { H1 } from "ui-library/Typography";
import { isLargeDesktopScreen, isSmalLaptop, isUltraHDScreen } from "utils/breakpoints";

export const Section = styled(Flex)`
    padding: 80px 16px;
    background-color: #F4B41A;
    width: 100%;
`

export const ImgTag = styled(Img)`
    width: 60%;
    object-fit: contain;

    ${isSmalLaptop} {
        width: 95%;
    }
`

export const ImgTagPos = styled(Img)<{ 
    left?: number; 
    right?: number; 
    top?: number; 
    bottom?: number;
    width?: number; 
    height?: number;
}>`
    position: absolute;
    width: 20vw;
    height: 30vw;
    right: 15%;
    object-fit: contain;

    ${isSmalLaptop} {
        width: 30vw;
        height: 40vw;
        right: -2%;
    }
`;

export const ColorText = styled.span`
    color: #F5F5F5;
    font-weight: 700;
`

export const InfoText = styled(H1)`
    @media screen and (min-width: 1080px) and (max-width: 1920px) {
        width: 800px;
    } 
`