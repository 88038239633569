import { Flex } from "ui-library/Flex";
import { ColorText, ImgContainer, ImgTag, Section, TextContainer } from "./styles";
import { Label, Title1 } from "ui-library/Typography";
import { Img } from "assests/icons";

const textData = [
  "Clients can access personalized workout plans and track their fitness progress. It provides an all-in-one platform for achieving and monitoring fitness goals.",
  "Using the ShapeFit client app, Clients can access personalized meal plans and track their nutrition progress. It offers a convenient way to monitor dietary goals and stay on track.",
  "Clients can track their habits using the client app.",
  "Clients can easily communicate with their trainers through the inbox feature in the ShapeFit client app, ensuring seamless support and engagement.",
  "Clients can track their body measurements using the ShapeFit client app, helping them monitor progress and stay motivated on their fitness journey."
];

export const FeatureDescription = () => {

  return (
    <Section>
      <Flex flexDirection="column" gap="2rem">

        <Flex width="90%" margin="auto">
          <Title1 center fontWeight={500}>
            With <ColorText>Shapefit</ColorText>,
            We don’t just help you run your business—We provide an exceptional experience for your clients as well with our client app.
          </Title1>
        </Flex>

        <Flex style={{ padding: '0 32px' }} justifyContent="space-between" gap="0.5rem" alignItemsCenter>
          <TextContainer flexDirection="column" gap="1rem" justifyContent="start">
            {textData.map((text, index) => (
              <Flex key={index} gap="0.3rem" alignItems="flex-start">
                <Img name="circle-check" width="20px" height="20px" />
                <Label fontWeight={400}>
                  {text}
                </Label>
              </Flex>
            ))}
          </TextContainer>
          <ImgContainer>
            <ImgTag name="hand-mobile" />
          </ImgContainer>
        </Flex>
      </Flex>
    </Section>
  );
}