import { Img } from "assests/icons";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { isDesktopScreen, isLargeDesktopScreen, isMobile, isSmalLaptop, isUltraHDScreen } from "utils/breakpoints";

export const Section = styled(Flex)`
  padding: 64px 0px 64px 64px;

  ${isMobile} {
    padding: 10px 0px 30px 10px;
  } 
`

export const FlexBox = styled(Flex) <{ active: boolean }>`
  width: 100%;
  padding: 14px;
  border-radius: 16px;
  border: ${({ active }) => (active ? "2px solid #143D59" : "1px solid #E9EBEC")} ;
  background-color: ${({ active }) => (active ? "#E9EBEC" : "#FFFFFF")};
  transition: background-color 0.3s ease;
  cursor: pointer;
`;

export const ImgTag = styled(Img)`
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  object-fit: contain;
  &.loaded {
      opacity: 1;
  }
`

export const ImgTagSpecial = styled(Img)`
  width: 75%;
  height 75%;
  transition: opacity 0.5s ease-in-out;
  object-fit: contain;
  &.loaded {
      opacity: 1;
  }
`

export const ImgTagPos = styled(Img) <{
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  width?: number;
  height?: number;
}>`
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  ${({ left }) => left !== undefined && `left: ${left}px;`}
  ${({ right }) => right !== undefined && `right: ${right}px;`}
  ${({ top }) => top !== undefined && `top: ${top}px;`}
  ${({ bottom }) => bottom !== undefined && `bottom: ${bottom}px;`}
  ${({ width }) => width !== undefined ? `width: ${width}px;` : `width: 400px;`}  
  ${({ height }) => height !== undefined ? `height: ${height}px;` : `height: 160px;`} 
  &.loaded {
      opacity: 1;
  }
`;

export const InfoContainer = styled(Flex)`
  width: 50%;

  ${isSmalLaptop} {
    width: 100%;
    padding-right: 64px;
  }

  ${isMobile} {
    padding-right: 10px;
  }
`;  

export const ImageContainer = styled(Flex)`
  width: 50%;
  
  ${isSmalLaptop} {
    display: none;
  }
`;