import React, { useEffect, useState } from "react";
import { Flex } from "ui-library/Flex";
import {
  HeaderContainer,
  LogoContainer,
  MenuIcon,
  MenuList,
  MenuItem,
  LogInButton,
  SignInButton,
} from "./styles";
import { Img } from "assests/icons";
import { Label, Subtext } from "ui-library/Typography";
import { Dropdown, Menu } from "antd";
import { ArrowRightOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Path } from "components/Router";

export const Header = () => {
  const [open, setOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [openIcon, setOpenIcon] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 930) {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const menu = () => (
    <Menu>
      <Menu.Item
        onMouseEnter={() => setHoveredItem("workout")}
        onMouseLeave={() => setHoveredItem(null)}
        onClick={() => navigate("/workout")}
      >
        <Flex gap="0.58rem" alignItemsCenter justifyContent="space-between">
          <Img name={hoveredItem === "workout" ? "workout-active" : "workout"} width={24} />
          <Subtext fontFamily="Montserrat" fontWeight={600} color={hoveredItem === "workout" ? "#F4B41A" : ""}>
            WORKOUT
          </Subtext>
        </Flex>
      </Menu.Item>
      <Menu.Item
        onMouseEnter={() => setHoveredItem("nutrition")}
        onMouseLeave={() => setHoveredItem(null)}
        onClick={() => navigate("/nutrition")}
      >
        <Flex gap="0.75rem" alignItemsCenter justifyContent="space-between">
          <Img name={hoveredItem === "nutrition" ? "workout-active" : "nutrition"} width={20} />
          <Subtext fontFamily="Montserrat" fontWeight={600} color={hoveredItem === "nutrition" ? "#F4B41A" : ""}>
            NUTRITION
          </Subtext>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  const toggleMenu = () => {
    setOpen((prev) => !prev);
  };

  const toggleIcon = () => {
    setOpenIcon((prev) => !prev);
  }

  return (
    <HeaderContainer justifyContent="space-between" alignItemsCenter>
      <LogoContainer to="/">
        <Img name="shape-fit-logo" height="40px" width="40px" />
        <Label fontWeight={600}>SHAPECOACH</Label>
      </LogoContainer>

      <MenuIcon isOpen={open} onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </MenuIcon>

      <MenuList open={open}>
        <MenuItem>
          <Dropdown overlay={menu}
            trigger={["hover"]}
            onVisibleChange={toggleIcon}
          >
            <Subtext fontWeight={600}>
              FOR PERSONAL TRAINERS {openIcon ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </Subtext>
          </Dropdown>
        </MenuItem>
        <MenuItem>
          <Link to={Path.FOR_CLIENTS}>
            <Subtext fontWeight={600}>FOR CLIENTS</Subtext>
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <Link to={Path.PRICING}>
            <Subtext fontWeight={600}>PRICING</Subtext>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to={Path.BLOG}>
            <Subtext fontWeight={600}>BLOG</Subtext>
          </Link>
        </MenuItem> */}

        <Flex gap="1rem" flexDirection={open ? "column" : "row"}>
          <LogInButton
            name="LOGIN"
            icon={<ArrowRightOutlined />}
            iconPosition={'end'}
            href={`${Path.APP_URL}/login`}
            shape="round"
          />

          <SignInButton
            name="SIGN UP"
            icon={<ArrowRightOutlined />}
            iconPosition={'end'}
            href={`${Path.APP_URL}/signup`}
            shape="round"
          />
        </Flex>
      </MenuList>
    </HeaderContainer>
  );
};