
import { Flex } from "ui-library/Flex";
import { FlexBox, FormContainer, GetStartedButton, ImageContainer, ImgTag, InputField, Section } from "./styles";
import { Label } from "ui-library/Typography";
import { Input } from "antd";
import { Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const selectBefore = (
  <Select defaultValue="+91">
    <Option value="+91">+91</Option>
    <Option value="+347">+347</Option>
  </Select>
);

export const FirstSection = () => {

    return (
        <Section flexDirection="column" gap="2rem">
            <Flex margin="auto" flexDirection="column" gap="1rem">
                <Flex margin="auto" flexDirection="column">
                    <Flex flexDirection="column" gap="1rem"  centered>
                        <FlexBox alignItemsCenter>
                            <Label fontWeight={600}>CONTACT US</Label>
                        </FlexBox>
                    </Flex>
                </Flex>
            </Flex>

            <Flex centered gap="1.5rem">
                <ImageContainer justifyContentCenter>
                    <ImgTag name="cup1"/>
                </ImageContainer>

                <FormContainer flexDirection="column" gap="1rem">
                    <Flex justifyContent="space-between" gap="0.5rem" width="100%">
                        <Flex width="50%" flexDirection="column" gap="0.5rem">
                            <Label>First Name*</Label>
                            <InputField size="large" placeholder="Enter First Name" />                        
                        </Flex>

                        <Flex width="50%" flexDirection="column" gap="0.5rem">
                            <Label>Second Name*</Label>
                            <InputField size="large" placeholder="Enter Second Name" />  
                        </Flex>
                    </Flex>

                    <Flex justifyContent="space-between" gap="0.5rem" width="100%">
                        <Flex width="50%" flexDirection="column" gap="0.5rem">
                            <Label>Email*</Label>
                            <InputField type="email" size="large" placeholder="Enter Email Address" />                        
                        </Flex>

                        <Flex width="50%" flexDirection="column" gap="0.5rem">
                            <Label>Mobile Number*</Label>
                            <InputField  addonBefore={selectBefore}  size="large" placeholder="Enter Mobile Number" />  
                        </Flex>
                    </Flex>

                    <Flex flexDirection="column" gap="0.5rem">
                        <Label>Website</Label>
                        <InputField size="large" placeholder="Enter Link" />                        
                    </Flex>

                    <Flex flexDirection="column" gap="0.5rem">
                        <Label>Message</Label>
                        <TextArea rows={8} placeholder="Enter Message" />
                    </Flex>


                    <Flex>
                        <GetStartedButton
                            name="SUBMIT"
                            onClick={() => console.log("hello")}
                            shape="round"
                        />
                    </Flex>
                </FormContainer>
            </Flex>
        </Section>
    );
}