import { Flex } from "ui-library/Flex";
import { CopyRightContainer, FooterContainer, FooterImage, FooterInfoContainer, Title } from "./styles";
import { Img } from "assests/icons";
import { H1, Label, Subtext, XtraSmallText } from "ui-library/Typography";
import { Link } from "react-router-dom";
import { Path } from "components/Router";

export const Footer = () => {
  return (
    <FooterContainer flexDirection="column">
      <FooterInfoContainer justifyContent="space-evenly" flexWrap gap="2rem">
        <FooterImage flexDirection="column" centered>
          <Img name="shape-fit-logo" width={128} height={128} />
          <Title>shapecoach</Title>
        </FooterImage>
        
        <Flex flexDirection="column" gap="0.5rem">
          <H1 fontWeight={700}>Features</H1>
          <Link to={Path.WORKOUTS}>
            <Label fontWeight={500}>For Personal Trainers</Label>
          </Link>
          <Link to={Path.FOR_CLIENTS}>
            <Label fontWeight={500}>For Clients</Label>
          </Link>
          {/* <Link to={Path.BLOG}>
            <Label fontWeight={500}>Blog</Label>
          </Link>
          <Link to={Path.PRICING}>
            <Label fontWeight={500}>Pricing</Label>
          </Link> */}
        </Flex>

        <Flex flexDirection="column" gap="0.5rem">
          <H1 fontWeight={700}>Company</H1>
          <Link to={Path.CONTACT_US}>
            <Label fontWeight={500}>Contact Us</Label>
          </Link>
          <Link to={Path.TERMS_CONDITIONS}>
            <Label fontWeight={500}>Terms & Conditions</Label>
          </Link>
          <Link to={Path.PRIVACY_POLICY}>
            <Label fontWeight={500}>Privacy Policy</Label>
          </Link>
        </Flex>

        <Flex flexDirection="column" gap="0.5rem">
          <H1 fontWeight={700}>Mobile App</H1>
          <Link to={Path.CLINET_MOBILE_APP}>
            <Label fontWeight={500}>Client Mobile App</Label>
          </Link>
        </Flex>
      </FooterInfoContainer>

      <CopyRightContainer justifyContentCenter>
        <Subtext>© Copyright 2024 Shapecoach – All Rights Reserved </Subtext>
      </CopyRightContainer>
    </FooterContainer>
  );
};
