import { Flex } from "ui-library/Flex";
import { ColorText, ContentWrapper, FlexContainer, Image, ImageContainer, ImageTag, ImgTag, ImgTagPos, InfoContainer, InfoText, OutlineContainer, Section, SubTitle, Text, Title } from "./styles";
import { H1, Label, Title1, Title2, Title3 } from "ui-library/Typography";
import { EmailConnect } from "components/Common/EmailConnect";
import { Img } from "assests/icons";
import { Features } from "../Features";

export const FirstSection = () => {

    return (
        <Flex flexDirection="column">
            <Section>
                <Flex justifyContentCenter gap="0.5rem">
                    <Flex centered>
                        <ImageContainer>
                            <ImgTag name="cp1"/>
                        </ImageContainer>

                        <FlexContainer width="50%" flexDirection="column" gap="1.1rem">
                            <SubTitle fontWeight={600} color="#143D59">CLIENT APP</SubTitle>
                            <Title fontWeight={700} color="#F4B41A">Fitness journey made easy through client app</Title>
                                
                            <EmailConnect icon />
                        </FlexContainer>
                    </Flex>
                </Flex>
            </Section>

            <ContentWrapper gap="1rem" reverse>
                <InfoContainer flexDirection="column" gap="0.5rem" justifyContentCenter >
                    <Img name="cp4" width={80} />
                    <Title3 fontWeight={600} color="#3EB506">TRACK PROGRESS</Title3>
                    <Text fontWeight={400}>Easily add and track various aspects of your fitness journey, including body metrics, food intake, and measurements. Upload relevant documents to keep all your progress in one convenient place. Stay organized and motivated as you work toward your goals!</Text>
                </InfoContainer>

                <Image position="relative">
                    <ImgTag name="cp2" />
                </Image>
            </ContentWrapper>

            <Features />

            <OutlineContainer>
                <Flex centered flexDirection="column" gap="2rem" >
                    <InfoText fontWeight={500} center color="#F5F5F5" >
                        A {" "}
                        <ColorText>Calendar</ColorText>
                        {" "} to manage your workouts, appointments, and training sessions. Stay organized with scheduled reminders from client, ensuring you never miss a session. Plan ahead and track your fitness journey with ease.
                    </InfoText>
                    
                    <Flex position="relative" centered >
                        <ImageTag name="cp7"/>
                        <ImgTagPos name="cp8"/>
                    </Flex>
                </Flex>
            </OutlineContainer>


            <ContentWrapper gap="1rem">
                <Image position="relative">
                    <ImgTag name="cp5" />
                </Image>

                <InfoContainer flexDirection="column" gap="0.5rem" justifyContentCenter >
                    <Img name="cp6" width={80} />
                    <Title3 fontWeight={600} color="#BEB706">TRACK MEASUREMENTS</Title3>
                    <Text fontWeight={400}>Track your body measurements and progress effortlessly in the client app. Monitor key metrics like weight, body fat, and muscle gain to stay on top of your fitness goals. Keep motivated with visual progress updates over time.</Text>
                </InfoContainer>
            </ContentWrapper>
        </Flex>
    );
}