import { Img } from "assests/icons";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { H1 } from "ui-library/Typography";

export const Section = styled(Flex)`
    padding: 64px 20px;
    background-color: #FFFAEF;
`

export const ImgTag = styled(Img)`
    width: 100%;
    height: auto;

    @media screen and (max-width: 930px) {
        display:  none;
    }
`

export const TextContainer = styled(Flex)`
    width: 50%;

    @media screen and (max-width: 930px) {
        width: 100%;
    }
`

export const ImgContainer = styled(Flex)`
    width: 50%;

    @media screen and (max-width: 930px) {
        width: 0%;
    }
`

export const ColorText = styled.span`
    font-size: 2.5rem;
    color: #F4B41A;
    font-weight: 600;
`