import { Link } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { Label } from "ui-library/Typography";
import { isDesktopScreen, isLargeDesktopScreen, isTab, isUltraHDScreen } from "utils/breakpoints";

export const Section = styled(Flex)`
    padding: 40px 40px 0px 40px;
`;

export const LinkTo = styled(Link)`
    color: #F4B41A;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 600;
    ${isDesktopScreen} {
        font-size: 18px;
    }
    ${isLargeDesktopScreen} {
        font-size: 20px;
    }
    ${isUltraHDScreen} {
        font-size: 22px;
    }
`

export const Italic = styled(Label)`
    font-style: italic;
    font-weight: 400;
`;

export const FlexBox = styled(Flex)`
    padding: 4px 40px;
    background-color: #F4B41A;
    border-radius: 8px;
    height: 40px;
`

export const InfoContainer = styled(Flex)`
    width: 80%;
    ${isTab} {
        width: 100%;
    }
`