import { Flex } from "ui-library/Flex";
import { FlexBox, FlexContainer, Section } from "./styles";
import { Img } from "assests/icons";
import { H1, H3, Label, Title2 } from "ui-library/Typography";

export const Feature = () => {

    return (
        <Section>
            <Flex flexDirection="column" gap="1rem">
                <FlexContainer gap="1rem" justifyContent="space-between">
                    <FlexBox width="50%" flexDirection="column" gap="0.5rem">
                        <Img name="np2" width={64} />
                        <Title2 fontWeight={600}>Create Item List and Diet Plans</Title2>
                        <H3 fontWeight={500}>Build customized item lists and diet plans to meet your clients' nutritional needs. Streamline meal planning for a more effective and personalized approach to nutrition.</H3>
                    </FlexBox>

                    <FlexBox width="50%" flexDirection="column" gap="0.5rem">
                        <Img name="np3" width={64} />
                        <Title2 fontWeight={600}>Macros List</Title2>
                        <H3 fontWeight={500}>Generate a detailed list of macronutrients for each client, including protein, carbs, and fats. Tailor these macro goals to support their fitness and nutrition objectives.</H3>
                    </FlexBox>
                </FlexContainer>

                <FlexContainer gap="1rem" justifyContent="space-between">
                    <FlexBox width="50%" flexDirection="column" gap="0.5rem">
                        <Img name="np4" width={64} />
                        <Title2 fontWeight={600}>Meal Plans</Title2>
                        <H3 fontWeight={500}>Create personalized meal plans tailored to each client’s goals. Help them stay on track with balanced nutrition and clear guidance.</H3>
                    </FlexBox>

                    <FlexBox width="50%" flexDirection="column" gap="0.5rem">
                        <Img name="np3" width={64} />
                        <Title2 fontWeight={600}>Macro goal setting & Tracking</Title2>
                        <H3 fontWeight={500}>Set macro goals and track progress with ease. Monitor your clients' nutrition to ensure they stay on course with their dietary objectives.</H3>
                    </FlexBox>
                </FlexContainer>
            </Flex>
        </Section>
    );
}