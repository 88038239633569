export const Path = {
  WORKOUTS: '/workout',
  FOR_CLIENTS: '/for-clients',
  BLOG: '/blogs',
  NUTRITION: '/nutrition',
  PRICING: '/pricing',
  CONTACT_US: '/contact-us',
  TERMS_CONDITIONS: '/terms-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  CLINET_MOBILE_APP: '/',
  APP_URL: "https://app.shapecoach.io"
}
