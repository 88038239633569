import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { H1 } from "ui-library/Typography";

export const Section = styled(Flex)`
    padding: 48px;

    @media screen and (max-width: 480px) {
        padding: 15px;
    }
`

export const FAQSection = styled(Flex)`
    width: 100%;
`;


export const FAQItem = styled(Flex) <{ isOpen: boolean }>`
    background-color: ${(props) => (props.isOpen ? "#E8ECEE" : "#FFFFFF")};
    border-radius: 8px;
    padding: 12px 16px;
    border: 1px solid #C8D0D6;
`;

export const FAQContent = styled(Flex)<{ isOpen: boolean }>`
  max-height: ${(props) => (props.isOpen ? "1000px" : "0")};
  height: auto;
  overflow: hidden;
  padding: ${(props) => (props.isOpen ? "1rem 0 0.5rem 0" : "0")};
  transition: max-height 0.5s ease-in-out, padding 0.3s ease-in-out;
`;
