import { Flex } from "ui-library/Flex";
import { ContentContainer, EnterEmailInput, Error, GetStartedButton, InputContainer, LabelText, Section } from "./styles";
import { H1, Label } from "ui-library/Typography";
import { useState } from "react";
import { validateEmail } from "utils";
import { Path } from "components/Router";
import { ArrowRightOutlined, CheckCircleFilled } from "@ant-design/icons"


export const GetStarted = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    
    const handleButtonClick = () => {
        if (validateEmail(email)) {
            window.location.href = `${Path.APP_URL}/login?email=${encodeURIComponent(email)}`;
        } else {
            setError(true);
        }
    }

    return (
        <Section justifyContentCenter>
            <ContentContainer gap="2rem" alignItemsCenter justifyContentCenter flexDirection="column">
                
                <Flex flexDirection="column" justifyContentCenter alignItemsCenter gap="0.5rem">
                    <H1 color="#FFFFFF" fontWeight={700}>The ultimate way to grow your business.</H1>
                    <LabelText color="#FFFFFF" fontWeight={400} center>Unlock new opportunities and streamline operations with our comprehensive tools. Experience enhanced client engagement and efficient management, paving the way for sustainable business growth.</LabelText>
                </Flex>

                <Flex flexDirection="column" gap="0.75rem">
                    <InputContainer gap="0.5rem" centered>
                        <Flex flexDirection="column" gap="0.2rem" centered>
                            <EnterEmailInput
                                placeholder="Enter your email"
                                value={email}
                                status={error ? "error" : ""}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError(!validateEmail(email));
                                }}
                            />
                            {error && email && (
                                <Error fontSize="12px" color="red">Enter valid e-mail</Error>
                            )}
                        </Flex>
                        
                        <GetStartedButton
                            name="Get Started"
                            onClick={handleButtonClick}
                            icon={<ArrowRightOutlined />}
                            iconPosition={'end'}
                            shape="round"
                        />
                    </InputContainer>

                    <InputContainer gap="3.1rem" justifyContent="space-between">
                        <Label color="#FFFFFF">
                            <CheckCircleFilled style={{ color: "#FFFFFF"}}  /> Train upto 10 clients during trail period
                        </Label>
                        <Label color="#FFFFFF">
                            <CheckCircleFilled style={{ color: "#FFFFFF" }} /> No credit card required
                        </Label>
                    </InputContainer>
                </Flex>
                
            </ContentContainer>
        </Section>
    );
}