import { Flex } from "ui-library/Flex";
import { ColorText, ImgTag, ImgTagPos, InfoText, Section } from "./styles";

export const Outline = () => {
    return (
        <Section>
            <Flex flexDirection="column" margin="auto" centered gap="1rem">
                <Flex position="relative" centered>
                    <ImgTag name="today" />
                    <ImgTagPos name="today1"
                        width={225}
                        height={410}
                        right={20}
                        top={50}
                    />
                </Flex>

                <InfoText center color="#FFFFFF">
                    ShapeFit is the {" "}
                    <ColorText>
                        ultimate client management solution
                    </ColorText>
                    , tailored specifically for fitness trainers, coaches, and wellness professionals.
                </InfoText>
            </Flex>
        </Section>
    );
}