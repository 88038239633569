import { Input } from "antd";
import styled from "styled-components";
import { Button } from "ui-library/Button";
import { Flex } from "ui-library/Flex";
import { Label, Subtext } from "ui-library/Typography";
import { isTab } from "utils/breakpoints";

export const FlexContainer = styled(Flex)`
  ${isTab} {
    align-items: center;
  }
`

export const FlexInfo = styled(Flex)`
  @media screen and (max-width: 620px) {
    flex-direction: column;
    gap: 1rem;
    justify-content: start;
    width: 100%;
  }
`

export const GetStartedButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  width: 144px;
  background-color: #F4B41A;
  color: #000000;
  border: 1px solid #000000;
  &:hover {
      color: #F4B41A !important;
      border: 1px solid #F4B41A !important;
  }
`

export const EnterEmailInput = styled(Input)`
  border-radius: 100px;
  height: 48px;
  width: 40vw;
  padding: 0px 16px;

  @media screen and (max-width: 1800px) {
    width: 30vw;
  }

  @media screen and (max-width: 980px) {
    width: 50vw;
  }
`

export const Error = styled(Subtext)`
  padding-left: 30px;
`