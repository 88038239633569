import { Flex } from "ui-library/Flex";
import { EnterEmailInput, Error, FlexContainer, FlexInfo, GetStartedButton } from "./styles";
import { Label } from "ui-library/Typography";
import { ArrowRightOutlined, CheckCircleFilled } from "@ant-design/icons"
import { useState } from "react";
import { Path } from "components/Router";
import { validateEmail } from "utils";

interface EmailConnectProps {
    textColor?: string;
    iconColor?: string;
    icon?: boolean;
    iconValue?: React.ReactNode;
    iconPosition?: 'start' | 'end';
}

export const EmailConnect = ({
    textColor = "#000000",
    iconColor = "green",
    icon = false,
    iconValue = <ArrowRightOutlined />,
    iconPosition = 'end'
    
}: EmailConnectProps) => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    
    const handleButtonClick = () => {
        if (validateEmail(email)) {
            window.location.href = `${Path.APP_URL}/login?email=${encodeURIComponent(email)}`;
        } else {
            setError(true);
        }
    }

    return (
        <FlexContainer flexDirection="column" gap="0.75rem" alignItems="start">
            <Flex justifyContent="flex-start" gap="0.5rem">
                <Flex flexDirection="column" gap="0.2rem">
                    <EnterEmailInput
                        placeholder="Enter your email"
                        value={email}
                        status={error ? "error" : ""}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setError(!validateEmail(email));
                        }}
                    />
                    {error && email && (
                        <Error color="red">Enter valid e-mail</Error>
                    )}
                </Flex>
                
                <GetStartedButton
                    name="Get Started"
                    onClick={handleButtonClick}
                    icon={icon ? iconValue : null}
                    iconPosition={iconPosition}
                    shape="round"
                />
            </Flex>

            <FlexInfo gap="3.1rem" justifyContent="space-between">
                <Label color={textColor}>
                    <CheckCircleFilled style={{ color: iconColor }}  /> Train upto 10 clients during trail period
                </Label>
                <Label color={textColor}>
                    <CheckCircleFilled style={{ color: iconColor }} /> No credit card required
                </Label>
            </FlexInfo>
        </FlexContainer>
    );
}