import { Input } from "antd";
import { Img } from "assests/icons";
import styled from "styled-components";
import { Button } from "ui-library/Button";
import { Flex } from "ui-library/Flex";
import { isDesktopScreen, isLargeDesktopScreen, isTab, isUltraHDScreen } from "utils/breakpoints";

export const Section = styled(Flex)`
    padding: 40px 40px 0px 40px;
`;

export const FlexBox = styled(Flex)`
    padding: 4px 40px;
    background-color: #F4B41A;
    border-radius: 8px;
    height: 40px;
`

export const ImgTag = styled(Img)`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const GetStartedButton = styled(Button)`
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    width: 100%;
    background-color: #143D59;
    color: #FFFFFF;
    border: 1px solid #000000;
    &:hover {
        color: #143D59 !important;
        border: 1px solid #143D59 !important;
    }
`

export const InputField = styled(Input)`
  font-size: 1rem;
  color: #7A7A7A;
  border: 1px solid #7A7A7A;
`
export const ImageContainer = styled(Flex)`
  width: 50%;

  ${isTab} {
    display: none;
  }
`

export const FormContainer = styled(Flex)`
  width: 50%;

  ${isTab} {
    width: 100%;
  }
`