const theme = {
    colorText: 'black',
    colorLink: 'blue',
    fontSize: 16,
    fontSizeSM: 14,
    background: '#143D59',
    primary: '#143D59',
    secondary: '#7A7A7A',
    backgroundSecondary: '#B3B3B3',
    headerTextColor: '#292D32',
    borderRadius: 8,
    
    fontFamily: 'Montserrat',
    fontColor: '#143D59',
  }
  export default theme;