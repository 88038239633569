import React, { useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { FAQContent, FAQItem, FAQSection, Section } from "./styles";
import { Label, Title3 } from "ui-library/Typography";
import { Flex } from "ui-library/Flex";

export const FAQs = () => {
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  const toggleFAQ = (index: number) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((i) => i !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  };

  const faqs = [
    {
      question: "What is personal training software?",
      answer: [
        `Personal training software is a tool that helps personal trainers manage their clients’ workouts and progress.
        It can be used to create custom workouts, track client progress, and monitor client compliance with assigned programs.`,
        `Personal trainers can use this software to more effectively manage their time and resources and provide their clients with a more engaging training experience.
        My PtHub is an all-encompassing personal trainer software that provides the necessary tools to manage your entire personal training business effectively.`
      ],
    },
    {
      question: "How much does personal training software cost?",
      answer: [
        "The cost varies depending on the platform and features. Many offer subscription models starting from $20/month."
      ],
    },
    {
      question: "How do I grow my personal training business?",
      answer: [
        "You can grow your personal training business by using tools to streamline client management. Creating a solid online presence and leveraging word-of-mouth can also help.",
      ],
    },
    {
      question: "Where can I find software for personal trainer business?",
      answer: [
        "There are many platforms like my PtHub that cater to personal trainers. Research and choose based on your needs.",
      ],
    },
  ];

  return (
    <Section>
      <FAQSection flexDirection="column" gap="1.5rem" justifyContentCenter>
        <Title3 fontWeight={600} center>
          Frequently Asked Questions
        </Title3>

        <Flex flexDirection="column" gap="0.5rem">
          {faqs.map((faq, index) => (
            <FAQItem flexDirection="column" key={index} isOpen={activeIndices.includes(index)}>
              <Flex cursor="pointer" justifyContent="space-between" onClick={() => toggleFAQ(index)}>
                <Label fontWeight={700}>{faq.question}</Label>
                {activeIndices.includes(index) ? <MinusOutlined /> : <PlusOutlined />}
              </Flex>

              <FAQContent isOpen={activeIndices.includes(index)} flexDirection="column" gap="1rem">
                {faq.answer.map((paragraph, pIndex) => (
                  <Label key={pIndex} fontWeight={400} color="#000000">
                    {paragraph}
                  </Label>
                ))}
              </FAQContent>
            </FAQItem>
          ))}
        </Flex>
      </FAQSection>
    </Section>
  );
};
