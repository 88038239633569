import { Img } from "assests/icons";
import styled from "styled-components";
import { Button } from "ui-library/Button";
import { Flex } from "ui-library/Flex";
import { H1 } from "ui-library/Typography";
 
export const Section = styled(Flex)`
    padding: 64px 48px;

    @media screen and (max-width: 480px) {
        padding: 64px 15px;
    }
`

export const ContentSection = styled(Flex)`
    width: 100%;
`;

export const LargeText = styled(H1)`
    text-align: center;
`

export const ArrowButton = styled(Button)`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    background-color: #F4B41A;
    &:hover {
        color: #F4B41A !important;
        border: 1px solid #F4B41A !important;
    }
`

export const TestimonialCard = styled(Flex)`
    min-width: 500px;
    min-height: 239px;
    border-radius: 8px;
    border: 1px solid #C8D0D6;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px #0000001A;
    overflow: hidden;
    background-color: #FFFFFF;

    @media screen and (max-width: 560px) {
        min-width: 100px;
        min-height: 150px;
    }
`

export const ImgWrapper = styled(Flex)`
    flex: 35%;
    flex-shrink: 0;
    padding-right: 12px;
`

export const ImgTag = styled(Img)`
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const ContentWrapper = styled(Flex)`
    padding: 8px 0px;;
`

export const TestimonialContainer = styled(Flex)`
    min-width: 91.2vw;
    overflow: hidden;
`

export const TestimonialList = styled(Flex)<{currentIndex: number, visibleCards: number}>`
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap; 
    transition: transform 0.5s ease-in-out;
`