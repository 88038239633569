import { Img } from "assests/icons";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { H1, Title1, Title2 } from "ui-library/Typography";
import { isDesktopScreen, isLaptopScreen, isLargeDesktopScreen, isLargeMobile, isSmalLaptop, isTab, isUltraHDScreen } from "utils/breakpoints";

export const Section = styled(Flex)`
    padding-left: 40px;
    ${isTab} {
        padding: 10px;
    }
`

export const FlexContainer = styled(Flex)`
    padding: 108px 0 24px 0;
    width: 50%;
    ${isTab} {
        padding-top: 30px;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`

export const ContentWrapper = styled(Flex)<{ reverse?: boolean }>`
  margin-right: ${({ reverse }) => (reverse ? "0px" : "40px")};
  margin-left: ${({ reverse }) => (reverse ? "60px" : "0px")};

  ${isLargeMobile} {
    padding: 40px 10px;
    margin: 0;
  }
`;

export const InfoContainer = styled(Flex)`
  width: 45%;

  ${isLargeMobile} {
    width: 100%;
    align-items: center;
  }
`

export const ImgTag = styled(Img)`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const ImageTag = styled(Img)`
    width: 60%;
    object-fit: contain;

    ${isLargeMobile} {
        width: 95%;
    }
`

export const Image = styled(Flex)`
    width: 55%;
    ${isLargeMobile} {
        display: none;
    }
`

export const Text = styled(Title2)`
    ${isLargeMobile} {
        text-align: center;
    }
`

export const ImageContainer = styled(Flex)`
    width: 50%;
    ${isTab} {
        display: none;
    }
`

export const Title = styled(Title1)`
    ${isTab} {
        text-align: center;
    }
`

export const SubTitle = styled(Title2)`
    ${isTab} {
        text-align: center;
    }
`

export const FlexBox = styled(Flex)`
    padding: 64px 0;
    background-color: #FFFAEF;
`

export const OutlineContainer = styled(Flex)`
    padding: 80px 16px;
    background-color: #143D59;
`

export const ImgTagPos = styled(Img)`
    position: absolute;
    width: 25vw;
    height: 30vw;
    right: 40%;
    object-fit: contain;

    ${isSmalLaptop} {
        width: 40vw;
        height: 40vw;
        right: 35%;
    }
`;

export const ColorText = styled.span`
    color: #F4B41A;
    font-weight: 600;
`

export const InfoText = styled(H1)`
    @media screen and (min-width: 1080px) and (max-width: 1920px) {
        width: 800px;
    } 
`