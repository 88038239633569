import { Flex } from "ui-library/Flex";
import { FlexBox, ImageContainer, ImgTag, ImgTagPos, ImgTagSpecial, InfoContainer, Section } from "./styles";
import { Img } from "assests/icons";
import { H2, H3, Label, Subtext } from "ui-library/Typography";
import { useEffect, useState } from "react";

const features = [
  {
    icon: "connect",
    title: "Connect & Engage",
    description: "Trainer can easily connect with Clients and stay engaged through personalized communication. ShapeFit fosters strong relationships, ensuring continuous support and motivation."
  },
  {
    icon: "nutrition1",
    title: "Personalized Training & Nutrition",
    description: "Tailored workout and nutrition plans designed to meet each client’s unique goals and needs. ShapeFit helps trainers create personalized programs for optimal results."
  },
  {
    icon: "schedule",
    title: "Scheduling & Appointments",
    description: "Easily manage your sessions with built-in scheduling and appointment tools. Automated reminders ensure you and your clients stay on track."
  },
  {
    icon: "tracking",
    title: "Tracking Client Progress & Hobbies",
    description: "Monitor client progress through detailed tracking of workouts and achievements. ShapeFit also allows you to note hobbies, helping to create more personalized fitness plans."
  },
  {
    icon: "ultimate",
    title: "The Ultimate Client App",
    description: "The Ultimate Client App enables trainers to efficiently track progress. With integrated communication features, trainers can provide personalized support and maintain strong client relationships."
  }
];

export const Features = () => {

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (true) {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % features.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <Section>
      <Flex gap="2rem" width="100%">
        <InfoContainer centered flexDirection="column" gap="0.75rem">
          {features.map((feature, index) => (
            <FlexBox onClick={() => setActiveIndex(index)} key={index} active={activeIndex === index} gap="0.75rem" alignItemsCenter>
              <Img name={feature.icon as "connect" | "nutrition1" | "schedule" | "tracking" | "ultimate"} width={64} />
              <Flex flexDirection="column" gap="0.25rem" >
                <H2 fontWeight={600}>{feature.title}</H2>
                <H3 fontWeight={400}>{feature.description}</H3>
              </Flex>
            </FlexBox>
          ))}
        </InfoContainer>

        <ImageContainer position="relative" flexGrow justifyContent="flex-end">
          {activeIndex === 0 && (
            <>
              <ImgTag width="100%" name="clients" loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')} />
              <ImgTagPos left={15} bottom={-50} name="addclients"
                loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')}
              />
            </>
          )}
          {activeIndex === 1 && (
            <>
              <ImgTag name="Training" loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')} />
              <ImgTagPos name="Training1" width={400} height={250} left={5} bottom={-100}
                loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')}
              />
              <ImgTagPos name="Training2" width={110} height={80} right={0} top={-40}
                loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')}
              />
            </>
          )}
          {activeIndex === 2 && (
            <>
              <ImgTag name="Calendar" loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')} />
              <ImgTagPos name="Calendar1" width={280} height={260} left={10} bottom={-70}
                loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')}
              />
            </>
          )}
          {activeIndex === 3 && (
            <>
              <ImgTag name="measure" loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')} />
              <ImgTagPos name="measure1" width={500} height={240} left={10} bottom={-90}
                loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')}
              />
            </>
          )}
          {activeIndex === 4 && (
            <>
              <ImgTagSpecial name="featureUltimate" loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')} />
              <ImgTagPos name="date" width={176} height={200} left={70} bottom={0}
                loading="lazy" onLoad={(e) => e.currentTarget.classList.add('loaded')}
              />
            </>
          )}
        </ImageContainer>
      </Flex>
    </Section>
  );
}

