import { Header } from "components/Common/Header"
import { LayoutContainer } from "./styles"
import { FAQs } from "components/Common/Faq"
import { GetStarted } from "components/Common/GetStarted"
import { Footer } from "components/Common/Footer/Footer"
import { Testimonials } from "components/Common/Testimonials"
import { FirstSection } from "./FirstSection"

export const ContactUs = () => {

    return (
        <LayoutContainer>
            <Header />
            <FirstSection />
            <Testimonials />
            <FAQs />
            <GetStarted />
            <Footer />
        </LayoutContainer>
    )
}