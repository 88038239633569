import { styled } from "styled-components";
import { Flex } from "ui-library/Flex";

export const FooterContainer = styled(Flex)`
    background-color: #FFFAEF;
    min-width: 100vw;
`;

export const FooterInfoContainer = styled(Flex)`
  padding: 40px 20px;
  align-items: flex-start;

  > * {
    flex: 1 1 200px;
  }
`

export const FooterImage = styled(Flex)`
   @media (max-width: 480px) {
    align-items: flex-start;
  }
`

export const CopyRightContainer = styled(Flex)`
    border-top: 1px solid #C4C4C4;
    padding: 10px;
`

export const Title = styled.h1`
    font-size: 32px;
    font-weight: 600;
`