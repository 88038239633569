import { Flex } from "ui-library/Flex";
import { ContentContainer, FlexBox, ImgTag, NavBarContainer, Section } from "./styles";
import { Img } from "assests/icons";
import { H1, H2, Label, Title2 } from "ui-library/Typography";
import { useEffect, useState } from "react";
import { FeatureDetail } from "./featureDetail";

const featureData = [
    {
      name: "cp3",
      title: "NUTRITION",
      description: "Clients can easily add and track their macros, including carbs, protein, fats, and calories. The app provides a simple way to log daily intake and stay aligned with their nutrition goals. Clients can monitor their progress and adjust their diet to optimize their results."
    },
    {
      name: "cp14",
      title: "WORKOUT",
      description: "Clients can easily share and track their workouts and progress. The app allows users to log exercises, monitor improvements, and stay motivated. With progress updates and personalized insights, clients can stay on top of their fitness journey."
    },
    {
      name: "cp15",
      title: "CHAT",
      description: "Clients can easily communicate with their trainers through the built-in chat option. This feature enables real-time messaging for quick updates, workout adjustments, or guidance. It keeps the connection between trainer and client seamless, ensuring continuous support and engagement. Stay connected for a more personalized training experience."
    },
    {
      name: "cp16",
      title: "NOTIFICATION",
      description: "Notification option ensures clients never miss an update. Whether it's workout reminders, progress reports, or messages from trainers, clients receive timely alerts directly on their devices. This feature keeps them engaged and on track with their fitness journey. Stay informed and motivated every step of the way."
    },
    {
      name: "cp17",
      title: "RM CALCULATOR",
      description: "Notification option ensures clients never miss an update. Whether it's workout reminders, progress reports, or messages from trainers, clients receive timely alerts directly on their devices. This feature keeps them engaged and on track with their fitness journey. Stay informed and motivated every step of the way."
    },
    {
        name: "",
        title: "",
        description: ""
      }
  ];

export const Features = () => {

    const [currIndex, setCurrIndex] = useState<number>(0);

    useEffect(() => {

        const screenWidth = window.innerWidth;

        if (screenWidth > 980) {
            const interval = setInterval(() => {
                setCurrIndex((prevIndex) => (prevIndex + 1) % featureData.length);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, []);

    const handleClick = (index: number) => {
        if (index === currIndex) {
            setCurrIndex(5);
        }
        else {
            setCurrIndex(index);
        }
    }

    return (
        <Section centered>
            <Flex flexDirection="column" centered gap="3rem">
                <NavBarContainer width="90%" gap="1rem" justifyContent="space-evenly">
                    <FlexBox width="20%" active={currIndex === 0} justifyContentCenter gap="1.5rem" onClick={() => handleClick(0)}>
                        <Flex gap="0.5rem" alignItemsCenter>
                            <Img name="cp9" width={32} />
                            <H2 fontWeight={600}>Nutrition</H2>
                        </Flex>

                        <ContentContainer handleDisplay active={currIndex === 0}>
                            <FeatureDetail
                                imageName={featureData[0].name}
                                title={featureData[0].title}
                                description={featureData[0].description}
                            />
                        </ContentContainer>
                    </FlexBox>

                    <FlexBox width="20%" active={currIndex === 1} justifyContentCenter gap="0.5rem" onClick={() => handleClick(1)}>
                        <Flex gap="0.5rem" alignItemsCenter>
                            <Img name="cp10" width={32} />
                            <H2 fontWeight={600}>Workout</H2>
                        </Flex>

                        <ContentContainer handleDisplay active={currIndex === 1}>
                            <FeatureDetail
                                imageName={featureData[1].name}
                                title={featureData[1].title}
                                description={featureData[1].description}
                            />
                        </ContentContainer>
                    </FlexBox>

                    <FlexBox width="20%" active={currIndex === 2} justifyContentCenter gap="0.5rem" onClick={() => handleClick(2)}>
                        <Flex gap="0.5rem" alignItemsCenter>
                            <Img name="cp11" width={32} />
                            <H2 fontWeight={600}>Chat</H2>
                        </Flex>

                        <ContentContainer handleDisplay active={currIndex === 2}>
                            <FeatureDetail
                                imageName={featureData[2].name}
                                title={featureData[2].title}
                                description={featureData[2].description}
                            />
                        </ContentContainer>
                    </FlexBox>

                    <FlexBox width="20%" active={currIndex === 3} justifyContentCenter gap="0.5rem" onClick={() => handleClick(3)}>
                        <Flex gap="0.5rem" alignItemsCenter>
                            <Img name="cp12" width={32} />
                            <H2 fontWeight={600}>Notification</H2>
                        </Flex>

                        <ContentContainer handleDisplay active={currIndex === 3}>
                            <FeatureDetail
                                imageName={featureData[3].name}
                                title={featureData[3].title}
                                description={featureData[3].description}
                            />
                        </ContentContainer>
                    </FlexBox>

                    <FlexBox width="20%" active={currIndex === 4} justifyContentCenter gap="0.5rem" onClick={() => handleClick(4)}>
                        <Flex gap="0.5rem" alignItemsCenter>
                            <Img name="cp13" width={32} />
                            <H2 fontWeight={600}>RM calculator</H2>
                        </Flex>

                        <ContentContainer handleDisplay active={currIndex === 4}>
                            <FeatureDetail
                                imageName={featureData[4].name}
                                title={featureData[4].title}
                                description={featureData[4].description}
                            />
                        </ContentContainer>
                    </FlexBox>
                </NavBarContainer>
                
                <ContentContainer>
                    <FeatureDetail
                        imageName={featureData[currIndex].name}
                        title={featureData[currIndex].title}
                        description={featureData[currIndex].description}
                    />
                </ContentContainer>
                
            </Flex>
        </Section>
    );
}