import React from 'react';
import { Routes, Route } from "react-router-dom";
import { ContentLayout } from './styles';
import { Home } from 'components/HomePage/Home/Home';
import { WorkoutPage } from 'components/WorkoutPage';
import { NutritionPage } from 'components/NutritionPage';
import { ContactUs } from 'components/ContactUs';
import { BlogDetail } from 'components/BlogDetail';
import { TermsConditions } from 'components/TermsConditions';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { BlogPage } from 'components/BlogPage';
import { PricingPage } from 'components/PricingPage';
import { ClientPage } from 'components/ClientPage';
import { ScrollToTop } from 'components/Common/Scroll';

function App() {
  return (
    <ContentLayout>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/workout' element={<WorkoutPage />} />
        <Route path='/nutrition' element={<NutritionPage />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />}  />
        {/* <Route path='/blogs' element={<BlogPage />} /> */}
        <Route path='/for-clients' element={<ClientPage />} />
        {/* <Route path='/pricing' element={<PricingPage />} /> */}
        <Route path='/blog-detail' element={<BlogDetail />} />
      </Routes>
    </ContentLayout>
  );
}

export default App;