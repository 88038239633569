import { css } from "styled-components";

export const breakpoints = {
  mobile: '(max-width: 480px)',
  largeMobile: '(max-width: 780px)',
  tab: '(max-width: 980px)',
  smallLaptop: '(max-width: 1080px)',
  laptop: '(min-width: 1025px) and (max-width: 1280px)',
  desktop: '(min-width: 1281px) and (max-width: 1440px)',
  largeDesktop: '(min-width: 1441px) and (max-width: 1920px)',
  ultraHD: '(min-width: 1921px)',
};

export const isTab = `@media screen and ${breakpoints.tab}`;
export const isLargeMobile = `@media screen and ${breakpoints.largeMobile}`;
export const isMobile = `@media screen and ${breakpoints.mobile}`;
export const isSmalLaptop = `@media screen and ${breakpoints.smallLaptop}`;
export const isLaptopScreen = `@media ${breakpoints.laptop}`;
export const isDesktopScreen = `@media ${breakpoints.desktop}`;
export const isLargeDesktopScreen = `@media ${breakpoints.largeDesktop}`;
export const isUltraHDScreen = `@media ${breakpoints.ultraHD}`;

export const responsiveFontSize = (baseSize: string) => css`
  font-size: ${baseSize};
  ${isLaptopScreen} {
    font-size: ${baseSize};
  }
  ${isDesktopScreen} {
    font-size: ${baseSize};
  }
  ${isLargeDesktopScreen} {
    font-size: ${baseSize};
  }
  ${isUltraHDScreen} {
    font-size: ${baseSize};
  }
`;