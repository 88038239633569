import { Img } from "assests/icons";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { Title2 } from "ui-library/Typography";
import { isLargeMobile, isTab } from "utils/breakpoints";

export const Section = styled(Flex)`
    padding: 50px 0px;
    background-color: #FFFAEF; 
`

export const NavBarContainer = styled(Flex)`
    background-color: #E9EBEC;
    padding: 8px;
    border-radius: 16px;

    ${isTab} {
        flex-direction: column;
        width: 90vw;
    }
`

export const FlexBox = styled(Flex) <{ active: boolean }>`
    padding: 4px 20px;
    background-color: ${({ active }) => (active ? "#F4B41A" : "#E9EBEC")};
    border-radius: 8px;
    cursor: pointer;

    ${isTab} {
        width: 100%;
        justify-content: start;
        flex-direction: column;
        padding-bottom: ${({ active }) => (active ? "10px" : "4px")};
    }
`

export const ImgTag = styled(Img)`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const FlexContainer = styled(Flex)`

`

export const ImageContainer = styled(Flex)`
    width: 50%;
    ${isLargeMobile} {
        display: none;
    }
`

export const InfoContainer = styled(Flex)`
    width: 50%;
    ${isTab} {
        margin-right: 0px;
    }
    ${isLargeMobile} {
        width: 100%;
        margin-right: 0px;
    }
`

export const ContentContainer = styled(Flex) <{ handleDisplay?: boolean, active?: boolean }>`

    display: ${({ handleDisplay }) => (handleDisplay ? "none" : "flex")};

    ${isTab} {
        display: ${({ handleDisplay, active }) => handleDisplay ? (active ? "flex" : "none") : "none"};
    }
`

export const Title = styled(Title2)`
    ${isTab} {
        color: #FFFFFF;
    }
    ${isLargeMobile} {
        display: none;
    }
`