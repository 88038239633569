import { Layout } from "antd";
import { Img } from "assests/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { H1, Label } from "ui-library/Typography";
import { isLargeMobile, isMobile, isTab } from "utils/breakpoints";

export const Section = styled(Flex)`
    padding: 40px 40px 0px 40px;
`;

export const ImgTag = styled(Img)`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const LinkTo = styled(Link)`
    color: #F4B41A;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 600;
`

export const Italic = styled(Label)`
    font-style: italic;
    font-weight: 400;
`;

export const FlexContainer = styled(Flex)`
    width: 850px;

    ${isTab} {
        width: 100%;
    }
`

export const InfoContainer = styled(Flex)`
    width: "80%"

    ${isTab} {
        width: 100%;
    }
`