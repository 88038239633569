import { Flex } from "ui-library/Flex";
import { FlexBox, InfoContainer, Italic, LinkTo, Section } from "./styles";
import { H1, Label, Subtext, Title3 } from "ui-library/Typography";
import { Img } from "assests/icons";

export const FirstSection = () => {

    return (
        <Section>
            <Flex centered flexDirection="column" gap="1rem">
                <Flex margin="auto" flexDirection="column">
                    <Flex flexDirection="column" gap="1rem"  centered>
                        <FlexBox alignItemsCenter>
                            <Label fontWeight={600}>PRIVACY POLICY</Label>
                        </FlexBox>
                        <Flex gap="2rem" centered>
                            <Flex gap="0.4rem" centered>
                                <Img name="bd2" width={16} />
                                <Subtext>Oct’ 12 2024</Subtext>
                            </Flex>

                            <Flex gap="0.4rem" centered>
                                <Img name="bd3" width={16} />
                                <Subtext>5 min read</Subtext>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <InfoContainer flexDirection="column" centered gap="0.75rem">

                    <Flex flexDirection="column" gap="0.5rem">
                        <Title3 fontWeight={700}>Understanding Your Audience </Title3>
                        <Label>To truly understand your members, start by {" "}
                            <LinkTo to={"/"}>gathering feedback regularly</LinkTo>.
                            Set up surveys or host informal chat sessions where they can express what they love about your workouts and what they’d like more of. This feedback is invaluable in tailoring programs to different skill levels and interests. May be your newcomers need more focus on form, while your advanced members crave high-intensity interval training.
                        </Label>

                        <Label>
                            The foundation of any successful {" "}
                            <LinkTo to={"/"}>CrossFit program</LinkTo> {" "} is understanding your audience. CrossFit Athletes come from all walks of life, with varying fitness goals and preferences. Some members might be seasoned athletes looking for intense challenges, while others might be newbies aiming for gradual progress. Recognizing these differences is crucial for crafting sessions that everyone can enjoy and benefit from.
                        </Label>

                        <Label>
                        Another effective way to get to know your members better is by observing their performance and participation during classes. Notice who thrives in group settings versus those who prefer individual challenges. By paying attention to these nuances, you can develop workout plans that cater to everyone’s needs and keep them coming back for more. 
                        </Label>
                    </Flex>

                    <Flex flexDirection="column" gap="0.5rem">
                        <Title3 fontWeight={700}>Staying Ahead of Trends</Title3>

                        <Flex flexDirection="column" gap="0.5rem">
                            <Label>
                                In the fast-paced world of fitness, staying ahead of trends can set your CrossFit gym apart from the rest.
                                The {" "}<LinkTo to={"/"}>CrossFit community</LinkTo> {" "} is constantly evolving with new techniques and equipment, which can breathe new life into your programming. Incorporating these innovations can keep your workouts challenging and exciting for your members.
                            </Label>

                            <Label>
                                One trend gaining traction is incorporating functional fitness equipment such as kettlebells and resistance bands. These versatile tools add variety to workouts and help improve strength and flexibility, outside of what may be required in your daily WOD. Additionally, incorporating different training methodologies, like high-intensity interval training (HIIT) or plyometrics, can ramp up the intensity and keep members engaged.  
                            </Label>

                            <Label>
                                To learn more about building better programs, check out our guide {" "}
                                <LinkTo to={"/"}>“How to Craft and Curate Effective Workout Programming.”</LinkTo> This resource provides valuable insights and strategies to craft innovative and effective workouts that keep members motivated and coming back for more. Learn More
                            </Label>
                        </Flex>
                        
                    </Flex>

                    <Flex flexDirection="column" gap="0.5rem">
                        <H1 fontWeight={700}>Building Community and Accountability</H1>

                        <Flex flexDirection="column" gap="0.5rem">
                            <Label>
                                In the fast-paced world of fitness, staying ahead of trends can set your CrossFit gym apart from the rest.
                                The {" "}<LinkTo to={"/"}>CrossFit community</LinkTo> {" "} is constantly evolving with new techniques and equipment, which can breathe new life into your programming. Incorporating these innovations can keep your workouts challenging and exciting for your members.
                            </Label>

                            <Label>
                                One trend gaining traction is incorporating functional fitness equipment such as kettlebells and resistance bands. These versatile tools add variety to workouts and help improve strength and flexibility, outside of what may be required in your daily WOD. Additionally, incorporating different training methodologies, like high-intensity interval training (HIIT) or plyometrics, can ramp up the intensity and keep members engaged.  
                            </Label>

                            <Label>
                                To learn more about building better programs, check out our guide {" "}
                                <LinkTo to={"/"}>“How to Craft and Curate Effective Workout Programming.”</LinkTo> This resource provides valuable insights and strategies to craft innovative and effective workouts that keep members motivated and coming back for more. Learn More
                            </Label>
                        </Flex>
                    </Flex>

                    <Flex flexDirection="column" gap="0.5rem">
                        <Title3 fontWeight={700}>The Role of Technology</Title3>

                        <Flex flexDirection="column" gap="0.5rem">
                            <Label>
                                Incorporating technology into your CrossFit programming can revolutionize the member experience. {" "}
                                <LinkTo to={"/"}>Fitness technology</LinkTo> {" "}has come a long way, offering many tools to enhance workouts and track progress. Leveraging these technologies can make workouts more engaging and help members achieve their goals.
                            </Label>

                            <Label>
                                Apps and wearables have become staples in the fitness world. They allow members to track their performance, set goals, and monitor their progress. By integrating these tools into your programming, you can offer personalized workout experiences that cater to each member’s unique needs.
                            </Label>
                            <Label>
                                Online platforms like {" "}
                                <LinkTo to={"/"}>Shapefit</LinkTo> {" "} provide a wealth of resources for both coaches and members. Coaches can access new workout ideas and training programs, while members receive personalized feedback and guidance. This level of customization ensures that each member gets the most out of their CrossFit experience.
                            </Label>
                            <Label>
                                Pro Tip: See how  {" "}
                                <LinkTo to={"/"}>Shapefit</LinkTo> {" "} integrates client tracking with {" "}
                                <LinkTo to={"/"}>wearable data</LinkTo>{" "} from the top providers like WHOOP, Garmin, Apple, FitBit, and more here.
                            </Label>
                        </Flex>
                    </Flex>
                </InfoContainer>

                <InfoContainer flexWrap gap="0.75rem">
                    <Label>Tags/ Filters:</Label>
                    <Italic>#Fitness</Italic>
                    <Italic>#Shapefit</Italic>
                    <Italic>#Technology</Italic>
                    <Italic>#Crossfit</Italic>
                </InfoContainer>
            </Flex>
        </Section>
    );
}