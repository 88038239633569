import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { isTab } from "utils/breakpoints";

export const Section = styled(Flex)`
  padding: 72px 40px;

  ${isTab} {
    padding: 40px 20px;
  }
`

export const FlexBox = styled(Flex)`
  border-radius: 16px;
  border: 1px solid #C8D0D6;
  padding: 16px;

  @media screen and (max-width: 600px) {
    width: 100%;
  } 
`

export const FlexContainer = styled(Flex)`
  @media screen and (max-width: 600px) {
    flex-direction: column;
  } 
`   
