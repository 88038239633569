import { Input } from "antd";
import styled from "styled-components";
import { Button } from "ui-library/Button";
import { Flex } from "ui-library/Flex";
import { Label, Subtext } from "ui-library/Typography";

export const Section = styled(Flex)`
    padding: 48px;

    @media screen and (max-width: 480px) {
        padding: 15px;
    }
`

export const ContentContainer = styled(Flex)`
    background-image: linear-gradient(to right, #f4b41a, #cb7630, #8f4634, #4a2426, #000000);
    border-radius: 16px;
    padding: 80px 16px;
    width: 100%;
`

export const LabelText = styled(Label)`
    text-align: center;
    width: 658px;

    @media screen and (max-width: 760px) {
        width: 100%;
    }
`

export const GetStartedButton = styled(Button)`
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  width: 144px;
  background-color: #F4B41A;
  color: #000000;
  border: 1px solid #000000;
  &:hover {
      color: #F4B41A !important;
      border: 1px solid #F4B41A !important;
  }

  @media screen and (max-width: 480px) {
    min-width: 80vw;
  }
`

export const EnterEmailInput = styled(Input)`
  border-radius: 100px;
  height: 48px;
  width: 440px;
  padding: 0px 16px;

  @media screen and (max-width: 760px) {
    width: 60vw;
  }

  @media screen and (max-width: 670px) {
    width: 40vw;
  }

  @media screen and (max-width: 480px) {
    min-width: 80vw;
  }
`

export const Error = styled(Label)`
  padding-left: 30px;
`

export const InputContainer = styled(Flex)`
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }
`
