import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ContentSection, LargeText, Section, ArrowButton, TestimonialCard, ImgTag, ImgWrapper, ContentWrapper, TestimonialContainer, TestimonialList } from "./styles";
import { Flex } from "ui-library/Flex";
import { H3, Label, Title2, Title3 } from "ui-library/Typography";

const testimonials = [
  {
    image: "Testimonial",
    text: "Clients can track their body measurements using the ShapeFit client app, helping them monitor progress and stay motivated on their fitness journey.",
    name: "Lara Beema",
    designation: "Personal Trainer",
  },
  {
    image: "Testimonial1",
    text: "Clients can track their body measurements using the ShapeFit client app, helping them monitor progress and stay motivated on their fitness journey.",
    name: "John Doe",
    designation: "Fitness Coach",
  },
  {
    image: "Testimonial2",
    text: "Clients can track their body measurements using the ShapeFit client app, helping them monitor progress and stay motivated on their fitness journey.",
    name: "Jane Smith",
    designation: "Personal Trainer",
  },
  {
    image: "Testimonial2",
    text: "Clients can track their body measurements using the ShapeFit client app, helping them monitor progress and stay motivated on their fitness journey.",
    name: "Alex Brown",
    designation: "Fitness Trainer",
  },
  {
    image: "Testimonial",
    text: "Clients can track their body measurements using the ShapeFit client app, helping them monitor progress and stay motivated on their fitness journey.",
    name: "Sara White",
    designation: "Health Coach",
  },
];

export const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(3);

  useEffect(() => {
    const updateVisibleCards = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1600) {
        setVisibleCards(4);
      }
      else if (screenWidth >= 1024) {
        setVisibleCards(3);
      } else if (screenWidth >= 680) {
        setVisibleCards(2);
      } else {
        setVisibleCards(1);
      }
    }
    
    updateVisibleCards();

    window.addEventListener("resize", updateVisibleCards);
    return () => window.removeEventListener("resize", updateVisibleCards);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + visibleCards) % testimonials.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - visibleCards + testimonials.length) % testimonials.length;
      return newIndex < 0 ? testimonials.length + newIndex : newIndex;
    });
  };

  const getVisibleTestimonials = () => {
    let result = [];

    for (let index = 0; index < visibleCards; index++) {
      result.push(testimonials[(currentIndex + index) % testimonials.length])
    }

    return result;
  }

  return (
    <Section>
      <ContentSection flexDirection="column" gap="1.25rem" justifyContentCenter>
        <Title2 center fontWeight={600}>Testimonials</Title2>
        <TestimonialContainer justifyContentCenter>
          <TestimonialList gap="1rem" currentIndex={currentIndex} visibleCards={visibleCards}>
            {getVisibleTestimonials().map((testimonial, index) => (
              <TestimonialCard key={index} justifyContent="space-between">
                <ImgWrapper>
                  <ImgTag name={testimonial.image as "Testimonial"} />
                </ImgWrapper>

                <ContentWrapper flexDirection="column" justifyContent="space-between">
                  <Label fontWeight={500}>
                    <Label fontWeight={700}>"</Label>
                      {testimonial.text}
                    <Label fontWeight={700}>"</Label>
                  </Label>

                  <Flex flexDirection="column" gap="0.25rem">
                    <Label fontWeight={600}>{testimonial.name}</Label>
                    <H3 fontWeight={400}>{testimonial.designation}</H3>
                  </Flex>
                </ContentWrapper>
              </TestimonialCard>
            ))}
          </TestimonialList>
        </TestimonialContainer>

        <Flex gap="1rem" justifyContent="end">
          <ArrowButton
            name=""
            icon={<ArrowLeftOutlined />}
            iconPosition={'end'}
            shape="circle"
            onClick={handlePrevious}
          />
          <ArrowButton
            name=""
            icon={<ArrowRightOutlined />}
            iconPosition={'end'}
            shape="circle"
            onClick={handleNext}
          />
        </Flex>
      </ContentSection>
    </Section>
  );
};
