import { styled } from "styled-components";
import { Button } from "ui-library/Button";
import { Flex } from "ui-library/Flex";
import { Link } from "react-router-dom";

export const HeaderContainer = styled(Flex)`
  padding: 0.6rem 2.5rem;
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  box-shadow: 0px 0px 10px 0px #0000001A;

  @media (max-width: 940px) {
    flex-wrap: wrap;
    min-width: 100vw;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
`;

export const MenuIcon = styled.div<{ isOpen: boolean }>`
  display: none;
  cursor: pointer;

  span {
    display: block;
    width: 25px;
    height: 3px;
    background: #333;
    margin: 5px 0;
    transition: all 0.3s ease-in-out;

    &:nth-child(1) {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(45deg) translate(0.34375rem, 6px)" : "rotate(0)"};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(-45deg) translate(0.3rem, -6px)" : "rotate(0)"};
    }
  }

  @media (max-width: 940px) {
    display: block;
  }
`;

export const MenuList = styled.ul<{ open: boolean }>`
  display: flex;
  list-style: none;
  gap: 2rem;
  align-items: center;

  @media screen and (max-width: 940px) {
    gap: 1.5rem;
    margin-top: ${({ open }) => (open ? "10px" : "-10px")};
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    max-height: ${({ open }) => (open ? "300px" : "0")};
    transition: max-height 0.8s cubic-bezier(0.25, 0.8, 0.25, 1),
      margin-top 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: #333;
  }
  span {
    &:hover {
      color: #f4b41a;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;

  a {
    padding: 0.5rem 1rem;
    background: #143d59;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;

    &:hover {
      background: #f4b41a;
    }
  }
`;

export const LogInButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #143D59;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    border: 2px solid #F4B41A !important;
    color: #F4B41A !important;
  }
`

export const SignInButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  background-color: #F4B41A;
  &:hover {
      color: #F4B41A !important;
      border: 1px solid #F4B41A !important;
  }
`