import { Flex } from "ui-library/Flex";
import { FlexContainer, ImageContainer, ImgTag, InfoContainer, Title } from "./styles";
import { H1, Title2 } from "ui-library/Typography";

interface Props {

    title: string;
    description: string;
    imageName: string;
}


export const FeatureDetail = ({title, description, imageName}: Props) => {
 
    return (
        <FlexContainer centered gap="3rem">
            <ImageContainer>
                <ImgTag name={imageName as "cp3" | "cp14" | "cp15" | "cp16" | "cp17"} />
            </ImageContainer>

            <InfoContainer flexDirection="column" gap="1rem" marginRight="40px">
                <Title fontWeight={700} color="#F4B41A">{title}</Title>
                <H1 fontWeight={500} color="#143D59">{description}</H1>
            </InfoContainer>
        </FlexContainer>
    );
}