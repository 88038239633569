import { Img } from "assests/icons";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import { Title1, Title2 } from "ui-library/Typography";
import { isDesktopScreen, isLaptopScreen, isLargeDesktopScreen, isTab, isUltraHDScreen } from "utils/breakpoints";

export const Section = styled(Flex)`
    padding-left: 40px;

    ${isTab} {
        padding-left: 0px;
    }
`

export const FlexContainer = styled(Flex)`
    padding: 108px 0 24px 0;
    width: 50%;

    ${isTab} {
        padding: 60px 10px 24px 10px;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`

export const ImgTag = styled(Img)`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const ImageContainer = styled(Flex)`
    width: 50%;
    ${isTab} {
        display: none;
    }
`

export const FlexBox = styled(Flex)`
    padding: 64px 0;
    background-color: #FFFAEF;
`

export const ColorText = styled.span`
    color: #F4B41A;
    font-weight: 600;
`

export const Title = styled(Title1)`
    ${isTab} {
        text-align: center;
    }
`

export const SubTitle = styled(Title2)`
    ${isTab} {
        text-align: center;
    }
`