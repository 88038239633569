import { Flex } from "ui-library/Flex";
import { FlexContainer, ImageContainer, ImgTag, Section, SubTitle, Title } from "./styles";
import { H1, Title1, Title2 } from "ui-library/Typography";
import { EmailConnect } from "components/Common/EmailConnect";

export const FirstSection = () => {

  return (
    <Section>
      <Flex centered>
        <Flex centered gap="0.5rem">
          <FlexContainer width="50%" flexDirection="column" gap="1.1rem">
            <SubTitle fontWeight={600} color="#143D59">TRAIN YOUR CLIENTS ONLINE WITH</SubTitle>
            <Title fontWeight={700} color="#F4B41A">The Ultimate All-In-One Personal Training Platform & App</Title>
            <EmailConnect icon />
          </FlexContainer>

          <ImageContainer width="50%">
            <ImgTag name="first" />
          </ImageContainer>
        </Flex>
      </Flex>
    </Section>
  );
}