import { Layout } from "antd";
import styled from "styled-components";
import { Button } from "ui-library/Button";

export const LayoutContainer = styled(Layout)`
    background-color: #FFFFFF;
    overflow-x: hidden;
`

export const GetStartedButton = styled(Button)`
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    width: 144px;
    background-color: #F4B41A;
    color: #000000;
    border: 1px solid #000000;
    &:hover {
        color: #F4B41A !important;
        border: 1px solid #F4B41A !important;
    }
`