import React from 'react';
import ShapeFitLogo from './header/shape-fit-logo.png'; 
import ShapeFitLogo1 from './header/shape-fit-logo-1.png'; 
import Workout from './header/workout.png';
import WorkoutActive from "./header/workout-active.png";
import Nutrition from "./header/nutrition.png";
import CircleCheck from "./PageImages/circle-check.png";
import HandMobile from "./PageImages/handMobile.png";
import Connect from "./PageImages/connect.png";
import Nutrition1 from "./PageImages/nutrition.png";
import Schedule from "./PageImages/schedule.png";
import Tracking from "./PageImages/tracking.png";
import Ultimate from "./PageImages/ultimate.png";
import Clients from "./PageImages/Clients.png";
import AddClients from "./PageImages/addclients.png";
import Training from "./PageImages/Training.png"
import Training1 from "./PageImages/Training2.png"
import Training2 from "./PageImages/Training3.png"
import Calendar from "./PageImages/Calendar.png";
import Calendar1 from "./PageImages/Calendar1.png";
import Measure from "./PageImages/measure.png";
import Measure1 from "./PageImages/measure1.png";
import FeatureUltimate from "./PageImages/FeatureUltimate1.png";
import Date from "./PageImages/Date.png";
import Today from "./PageImages/Today.png";
import Today1 from "./PageImages/Today1.png";
import First from "./PageImages/first.png";
import Testimonial from "./PageImages/Testimonial.png"
import Testimonial1 from "./PageImages/Testimonia1.png"
import Testimonial2 from "./PageImages/Testimonial2.png"
import Testimonial3 from "./PageImages/Testimonial3.jpg"
import WP1 from "./PageImages/wp1.png"
import WP2 from "./PageImages/wp2.png"
import WP3 from "./PageImages/wp3.png"
import WP4 from "./PageImages/wp4.png"
import WP5 from "./PageImages/wp5.png"
import WP6 from "./PageImages/wp6.png"
import WP7 from "./PageImages/wp7.png"
import WP8 from "./PageImages/wp8.png"
import NP1 from "./PageImages/np1.png";
import NP2 from "./PageImages/np2.png";
import NP3 from "./PageImages/np3.png";
import NP4 from "./PageImages/np4.png";
import NP5 from "./PageImages/np5.png";
import NP6 from "./PageImages/np6.png";
import NP7 from "./PageImages/np7.png";
import CUP1 from "./PageImages/cup1.png";
import BD1 from "./PageImages/bd1.png";
import BD2 from "./PageImages/bd2.png";
import BD3 from "./PageImages/bd3.png";
import BD4 from "./PageImages/bd4.png";
import BP1 from "./PageImages/bp1.png";
import BP2 from "./PageImages/bp2.png";
import BP3 from "./PageImages/bp3.png";
import BP4 from "./PageImages/bp4.png";
import BP5 from "./PageImages/bp5.png";
import BP6 from "./PageImages/bp6.png";
import BP7 from "./PageImages/bp7.png";
import BP8 from "./PageImages/bp8.png";
import BP9 from "./PageImages/bp9.png";
import BP10 from "./PageImages/bp10.png";
import BP11 from "./PageImages/bp11.png";
import BP12 from "./PageImages/bp12.png";
import PP1 from "./PageImages/pp1.png";
import PP2 from "./PageImages/pp2.png";
import CP1 from "./PageImages/cp1.png";
import CP2 from "./PageImages/cp2.png";
import CP3 from "./PageImages/cp3.png";
import CP4 from "./PageImages/cp4.png";
import CP5 from "./PageImages/cp5.png";
import CP6 from "./PageImages/cp6.png";
import CP7 from "./PageImages/cp7.png";
import CP8 from "./PageImages/cp8.png";
import CP9 from "./PageImages/cp9.png";
import CP10 from "./PageImages/cp10.png";
import CP11 from "./PageImages/cp11.png";
import CP12 from "./PageImages/cp12.png";
import CP13 from "./PageImages/cp13.png";
import CP14 from "./PageImages/cp14.png";
import CP15 from "./PageImages/cp15.png";
import CP16 from "./PageImages/cp16.png";
import CP17 from "./PageImages/cp17.png";
import { ResponsiveImg } from "./styles";

export interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    name:
    | 'shape-fit-logo'
    | 'shape-fit-logo-1'
    | 'workout'
    | 'workout-active'
    | 'nutrition'
    | 'circle-check'
    | 'hand-mobile'
    | 'connect'
    | 'nutrition1'
    | 'schedule'
    | 'tracking'
    | 'ultimate'
    | 'clients'
    | 'addclients'
    | 'Training'
    | 'Training1'
    | 'Training2'
    | 'Calendar'
    | 'Calendar1'
    | 'measure'
    | 'measure1'
    | 'featureUltimate'
    | 'date'
    | 'today'
    | 'today1'
    | 'first'
    | 'Testimonial'
    | 'Testimonial1'
    | 'Testimonial2'
    | 'Testimonial3'
    | 'wp1'
    | 'wp2'
    | 'wp3'
    | 'wp4'
    | 'wp5'
    | 'wp6'
    | 'wp7'
    | 'wp8'
    | 'np1'
    | 'np2'
    | 'np3'
    | 'np4'
    | 'np5'
    | 'np6'
    | 'np7'
    | 'cup1'
    | 'bd1'
    | 'bd2'
    | 'bd3'
    | 'bd4'
    | 'bp1'
    | 'bp2'
    | 'bp3'
    | 'bp4'
    | 'bp5'
    | 'bp6'
    | 'bp7'
    | 'bp8'
    | 'bp9'
    | 'bp10'
    | 'bp11'
    | 'bp12'
    | 'pp1'
    | 'pp2'
    | 'cp1'
    | 'cp2'
    | 'cp3'
    | 'cp4'
    | 'cp5'
    | 'cp6'
    | 'cp7'
    | 'cp8'
    | 'cp9'
    | 'cp10'
    | 'cp11'
    | 'cp12'
    | 'cp13'
    | 'cp14'
    | 'cp15'
    | 'cp16'
    | 'cp17';
    size?: number;
    fill?: string; 
    width?: string | number;
    height?: string | number;
}

export const Img = ({ name, size, height, width, ...imgProps }: ImgProps) => {
    let src;

    switch (name) {
        case 'shape-fit-logo':
            src = ShapeFitLogo;
            break;
        case 'shape-fit-logo-1':
            src = ShapeFitLogo1;
            break;
        case 'workout':
            src = Workout;
            break;
        case 'workout-active':
            src = WorkoutActive;
            break;
        case 'nutrition':
            src = Nutrition;
            break;
        case 'circle-check':
            src = CircleCheck;
            break;
        case 'hand-mobile':
            src = HandMobile;
            break;
        case 'connect':
            src = Connect;
            break;
        case 'nutrition1':
            src = Nutrition1;
            break;
        case 'schedule':
            src = Schedule;
            break;
        case 'tracking':
            src = Tracking;
            break;
        case 'ultimate':
            src = Ultimate;
            break;
        case 'clients':
            src = Clients;
            break;
        case 'addclients':
            src = AddClients;
            break;
        case 'Training':
            src = Training;
            break;
        case 'Training1':
            src = Training1;
            break;
        case 'Training2':
            src = Training2;
            break;
        case 'Calendar':
            src = Calendar;
            break;
        case 'Calendar1':
            src = Calendar1;
            break;
        case 'measure':
            src = Measure;
            break;
        case 'measure1':
            src = Measure1;
            break;
        case 'featureUltimate':
            src = FeatureUltimate;
            break;
        case 'date':
            src = Date;
            break;
        case 'today':
            src = Today;
            break;
        case 'today1':
            src = Today1;
            break;
        case 'first':
            src = First;
            break;
        case 'Testimonial':
            src = Testimonial;
            break;
        case 'Testimonial1':
            src = Testimonial1;
            break;
        case 'Testimonial2':
            src = Testimonial2;
            break;
        case 'Testimonial3':
            src = Testimonial3;
            break;
        case 'wp1':
            src = WP1;
            break;
        case 'wp2':
            src = WP2;
            break;
        case 'wp3':
            src = WP3;
            break;
        case 'wp4':
            src = WP4;
            break;
        case 'wp5':
            src = WP5;
            break;
        case 'wp6':
            src = WP6;
            break;
        case 'wp7':
            src = WP7;
            break;
        case 'wp8':
            src = WP8;
            break;
        case 'np1':
            src = NP1;
            break;
        case 'np2':
            src = NP2;
            break;
        case 'np3':
            src = NP3;
            break;
        case 'np4':
            src = NP4;
            break;
        case 'np5':
            src = NP5;
            break;
        case 'np6':
            src = NP6;
            break;
        case 'np7':
            src = NP7;
            break;
        case 'cup1':
            src = CUP1;
            break;
        case 'bd1':
            src = BD1;
            break;
        case 'bd2':
            src = BD2;
            break;
        case 'bd3':
            src = BD3;
            break;
        case 'bd4':
            src = BD4;
            break;
        case 'bp1':
            src = BP1;
            break;
        case 'bp2':
            src = BP2;
            break;
        case 'bp3':
            src = BP3;
            break;
        case 'bp4':
            src = BP4;
            break;
        case 'bp5':
            src = BP5;
            break;
        case 'bp6':
            src = BP6;
            break;
        case 'bp7':
            src = BP7;
            break;
        case 'bp8':
            src = BP8;
            break;
        case 'bp9':
            src = BP9;
            break;
        case 'bp10':
            src = BP10;
            break;
        case 'bp11':
            src = BP11;
            break;
        case 'bp12':
            src = BP12;
            break;
        case 'pp1':
            src = PP1;
            break;
        case 'pp2':
            src = PP2;
            break;
        case 'cp1':
            src = CP1;
            break;
        case 'cp2':
            src = CP2;
            break;
        case 'cp3':
            src = CP3;
            break;
        case 'cp4':
            src = CP4;
            break;
        case 'cp5':
            src = CP5;
            break;
        case 'cp6':
            src = CP6;
            break;
        case 'cp7':
            src = CP7;
            break;
        case 'cp8':
            src = CP8;
            break;
        case 'cp9':
            src = CP9;
            break;
        case 'cp10':
            src = CP10;
            break;
        case 'cp11':
            src = CP11;
            break;
        case 'cp12':
            src = CP12;
            break;
        case 'cp13':
            src = CP13;
            break;
        case 'cp14':
            src = CP14;
            break;
        case 'cp15':
            src = CP15;
            break;
        case 'cp16':
            src = CP16;
            break;
        case 'cp17':
            src = CP17;
            break;
        default:
            return <div />;
    }

    return <img src={src} alt={name} width={width} height={height} {...imgProps} />;
};