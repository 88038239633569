import { Img } from "assests/icons";
import styled from "styled-components";
import { Flex } from "ui-library/Flex";
import Image from "../../../assests/icons/PageImages/workoutPage.jpg";
import { isLargeDesktopScreen, isLargeMobile, isUltraHDScreen } from "utils/breakpoints";
import { Title2 } from "ui-library/Typography";

export const FlexContainer = styled(Flex)`
  width: 100%;
  height: 747px;
  background-image: url(${Image}); 
  background-size: cover;
  background-position: center;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  ${isLargeDesktopScreen} {
    height: 900px;
  }
  ${isUltraHDScreen} {
    height: 1100px;
  }
`;

export const ContentContainer = styled(Flex)`
  max-width: 800px;
  padding: 10px;
`

export const ContentWrapper = styled(Flex)<{ reverse?: boolean }>`
  padding: 40px 0;
  margin-right: ${({ reverse }) => (reverse ? "0px" : "40px")};
  margin-left: ${({ reverse }) => (reverse ? "40px" : "0px")};

  ${isLargeMobile} {
    padding: 40px 10px;
    margin: 0;
  }
`;


export const ImgTag = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const ImgTagPos = styled(Img)<{ 
    left?: number; 
    right?: number; 
    top?: number; 
    bottom?: number;
    width?: number; 
    height?: number;
}>`
    position: absolute;
    ${({ left }) => left !== undefined && `left: ${left}px;`}
    ${({ right }) => right !== undefined && `right: ${right}px;`}
    ${({ top }) => top !== undefined && `top: ${top}px;`}
    ${({ bottom }) => bottom !== undefined && `bottom: ${bottom}px;`}
    ${({ width }) => width !== undefined ? `width: ${width}px;` : `width: 400px;`}  
    ${({ height }) => height !== undefined ? `height: ${height}px;` : `height: 160px;`} 
`;

export const ImageContainer = styled(Flex)`
  width: 55%;
  ${isLargeMobile} {
    display: none;
  }
`

export const InfoContainer = styled(Flex)`
  width: 45%;

  ${isLargeMobile} {
    width: 100%;
    align-items: center;
  }
`

export const Text = styled(Title2)`
  ${isLargeMobile} {
    text-align: center;
  }
`