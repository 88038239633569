import { Flex } from "ui-library/Flex";
import {  ContentContainer, ContentWrapper, FlexContainer, ImageContainer, ImgTag, ImgTagPos, InfoContainer, Text } from "./styles";
import { H1, Label, Title1, Title2, Title3 } from "ui-library/Typography";
import { EmailConnect } from "components/Common/EmailConnect";
import { Img } from "assests/icons";


export const FirstSection = () => {

    return (
        <Flex flexDirection="column">
            <FlexContainer justifyContentCenter >
                <Flex marginTop="-80px">
                    <ContentContainer  flexDirection="column" justifyContentCenter alignItemsCenter gap="1rem">
                        <Title1 fontSize="40px" fontWeight={700} color="#FFFFFF" center>
                            Workout Program Creator - Design better workouts in less time.
                        </Title1>
                        <Label fontWeight={600} color="#FFFFFF" center>
                            Design and build workouts for all your personal training clients. Discover how you can create workouts with our extensive library of over 7,500 HD video exercises.
                        </Label>

                        <EmailConnect textColor="#FFFFFF"/>
                    </ContentContainer>
                </Flex>
            </FlexContainer>

            <ContentWrapper gap="1rem">
                <ImageContainer position="relative">
                    <ImgTag name="wp1" />
                    <ImgTagPos name="wp2" 
                        width={323}
                        height={250}
                        right={20}
                        bottom={-20}
                    />
                </ImageContainer>

                <InfoContainer flexDirection="column" gap="0.5rem" justifyContentCenter >
                    <Img name="wp7" width={80} />
                    <Title3 fontWeight={600} color="#BEB706">CREATE WORKOUTS</Title3>
                    <Text fontWeight={400}>Create workouts that include detailed exercises, helpful links, and instructional videos. Easily share these resources with clients for a more engaging and effective training experience.</Text>
                </InfoContainer>
            </ContentWrapper>

            <ContentWrapper gap="1rem" reverse>
                <InfoContainer flexDirection="column" gap="0.5rem" justifyContentCenter >
                    <Img name="wp8" width={80} />
                    <Title3 fontWeight={600} color="#ED0000">TRAINING TEMPLATES</Title3>
                    <Text fontWeight={400}>Create customizable training plan templates that can be easily replicated across clients. Save time while offering personalized programs for each individual.</Text>
                </InfoContainer>


                <ImageContainer position="relative">
                    <ImgTag name="wp3" />
                    <ImgTagPos name="wp4" 
                        width={134}
                        height={95}
                        left={20}
                        bottom={-40}
                    />
                </ImageContainer>
            </ContentWrapper>

            <ContentWrapper gap="1rem" marginRight="40px">
                <ImageContainer position="relative">
                    <ImgTag name="wp5" />
                    <ImgTagPos name="wp6" 
                        width={134}
                        height={95}
                        right={20}
                        bottom={-30}
                    />
                </ImageContainer>

                <InfoContainer flexDirection="column" gap="0.5rem" justifyContentCenter >
                    <Img name="wp7" width={80} />
                    <Title3 fontWeight={600} color="#BEB706">WORKOUTS & PROGRAMS</Title3>
                    <Text fontWeight={400}>Assign customized workouts and programs to each client’s needs. Streamline the process while delivering effective, personalized fitness plans.</Text>
                </InfoContainer>
            </ContentWrapper>
        </Flex>
    );
}