import { Flex } from "ui-library/Flex";
import { ColorText, FlexBox, FlexContainer, ImageContainer, ImgTag, Section, SubTitle, Title } from "./styles";
import { H1, Label, Title1, Title2 } from "ui-library/Typography";
import { EmailConnect } from "components/Common/EmailConnect";

export const FirstSection = () => {

    return (
        <Flex flexDirection="column">
            <Section>
                <Flex justifyContentCenter>
                    <Flex centered gap="0.5rem">
                        <FlexContainer flexDirection="column" gap="1.1rem">
                            <SubTitle fontWeight={600} color="#143D59">NUTRITION TRACKING</SubTitle>
                            <Title fontWeight={700} color="#F4B41A">Fuel powerful results with nutrition coaching.</Title>
                                
                            <EmailConnect icon />
                        </FlexContainer>

                        <ImageContainer>
                            <ImgTag name="np1"/>
                        </ImageContainer>
                    </Flex>
                    
                </Flex>
            </Section>

            <FlexBox>
                <Flex width="90%" margin="auto">
                    <Title1 center fontWeight={400}>
                        With {" "}
                        <ColorText>
                            Shapefit
                        </ColorText>
                        , Set macro goals and monitor your client's nutrition progress. Keep track of their dietary habits to support their fitness journey.
                    </Title1>
                </Flex>
            </FlexBox>
        </Flex>
    );
}