import { Flex } from "ui-library/Flex";
import { ColorText, ImgTag, ImgTagPos, InfoText, Section } from "./styles";
import { H1, Label } from "ui-library/Typography";

export const Outline = () => {
    return (
        <Section>
            <Flex centered flexDirection="column" margin="auto" gap="0.5rem">
                <Flex position="relative" centered>
                    <ImgTag name="np6" />
                    <ImgTagPos name="np7"
                        width={225}
                        height={302}
                        right={-40}
                        top={50}
                    />
                </Flex>

                <InfoText fontWeight={600} center>
                    In just a few clicks, Trainers can assign your clients their nutrition plans and macro goals. Track their {" "}
                    <ColorText>
                        daily progress
                    </ColorText> and guide them toward {" "}
                    <ColorText>
                        achieving their objectives
                    </ColorText>
                    .
                </InfoText>
            </Flex>
        </Section>
    );
}