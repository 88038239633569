import { FAQs } from 'components/Common/Faq';
import { FeatureDescription } from 'components/HomePage/FeatureDescription';
import { Features } from 'components/HomePage/Features';
import { Footer } from 'components/Common/Footer/Footer';
import { GetStarted } from 'components/Common/GetStarted';
import { GetStartedButton } from './styles';
import { Testimonials } from 'components/Common/Testimonials';
import { Flex } from 'ui-library/Flex';
import { Header } from 'components/Common/Header';
import { LayoutContainer } from './styles';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Outline } from 'components/HomePage/Outline';
import { FirstSection } from 'components/HomePage/FirstSection';
import { Path } from 'components/Router';


export const Home = () => {

    return (
        <LayoutContainer>
            <Header />
            <FirstSection />
            <Outline />
            <Features />
            <Flex height='80px' justifyContentCenter alignItems='start'>
                <GetStartedButton
                    name="Get Started"
                    onClick={() => console.log("hello")}
                    icon={<ArrowRightOutlined />}
                    iconPosition={'end'}
                    href={Path.APP_URL}
                    shape="round"
                />
            </Flex>
            <FeatureDescription />
            <Testimonials />
            <FAQs />
            <GetStarted />
            <Footer />
        </LayoutContainer>
    );
}